import React from 'react'

import Image from './images'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import AppBadge from './images/appstoreus.svg'
import './styles/app.styl'

const App = ({ name, icon, features, preview }) => (
    <div className='app'>
      <h3 className='app-title'>{name}</h3>
      <span className='app-subtitles'>
        <h5 className='app-subtitle'>#2 in App Store games</h5>
        <h5 className='app-subtitle'>Over 6 million installs</h5>
      </span>
      <div className='app-grid'>
        <AppHeader icon={icon} />
        <AppInfo name={name} features={features} />
        {preview && <Preview preview={preview} />}
      </div>
    </div>
)

export default App

const AppHeader = ({ icon }) => ( //shows app icon
  <div className='app-header'>
    <Image className='app-icon' name={icon} />
  </div>
)

const AppInfo = ({ name, features }) => ( //shows feature list & appstore dl button
  <div className='app-info'>
    {features && <Features name={name} features={features}/>}
    <a className='applelink' href='https://itunes.apple.com/us/app/idle-ball-crush/id1381559021?mt=8' alt={name + ' download on the App Store link'}>
      <object type='image/svg+xml' data={AppBadge} aria-label={name + ' download on the Ap Store link'} style={{pointerEvents: 'none'}} />
    </a>
  </div>
)

const Features = ({ name, features }) => ( //creates feature list
  <ul>
    {features.map((feature, index) => (
      <li key={name + '_feature_' + index}>
        <FontAwesomeIcon className="checkmark" icon={faCheck} size='lg'/>
        {feature.feature}
      </li>
    ))}
  </ul>
)

const Preview = ({ preview }) => ( //preview image
  <div className='app-preview'>
    <Image name={preview} />
  </div>
)