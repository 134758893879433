import React from 'react'

import Layout from '../components/layout'
import App from '../components/app'

import './styles/index.styl'

const IndexPage = ({ data }) => (
  <Layout>
    <div id='main'>

      <section id='apps'>
        {data.allAppsJson.edges.map((app) => {
          return <App name={app.node.name} icon={app.node.icon} features={app.node.features} preview={app.node.preview} key={app.node.name} />
        })}
      </section>

      <section id='about'>
        <h2 className="section-title">About Us</h2>
        <p id='aboutus'>{data.allDataJson.edges[0].node.aboutUs}</p>
      </section>

      <section id='contact'>
        <h2 className="section-title">Contact: </h2>
        <h5 className="section-title">
          <a className="theme-link" href="mailto:contact@snakebacon.com" target='_blank' rel='noopener noreferrer'>
            contact@snakebacon.com
          </a>
        </h5>
        <ContactForm/>
      </section>

    </div>
  </Layout>
)

const ContactForm = () => (
<form id='contact-form' method='POST' action='https://formspree.io/contact@snakebacon.com'>
  <input className='contact-input' type='email' name='_replyto' placeholder='Your email'/>
  <input className='contact-input' type='text' name='_subject' placeholder='Subject'/>
  <textarea className='contact-input' name='message' placeholder='Your message'/>
  <button className="clean-btn contact-button" type='submit'>Submit</button>
</form>
)

export default IndexPage

export const query = graphql`
  query IndexPageQuery {
    allAppsJson {
      edges {
        node {
          name
          icon
          preview
          features {
            feature
          }
        }
      }
    }
    allMembersJson {
      edges {
        node {
          name
          bio
          img
          social {
            twitter
            email
          }
        }
      }
    }
    allDataJson {
      edges {
        node {
          aboutUs
        }
      }
    }
  }
`